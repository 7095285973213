import React from "react";
import { NavbarPublic } from "../../components/NavbarPublic";
import { getUserPublicInformation, listGifts } from "../../services/tipddy";
import "./gifts.css";
import { ShareWishCard } from "../../components/ShareWishCard";
import ShareWishFooter from "../../components/ShareWishFooter";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const CompartirRegalos = () => {
  const [isValid, setValid] = React.useState(true);
  const [obj, setObj] = React.useState({});
  const [userData, setUserData] = React.useState(null);
  const [deseos, setDeseos] = React.useState([]);

  React.useEffect(() => {
    const url = window.location.href;
    const urlSearchParams = new URLSearchParams(url.split("?")[1]); // get the URLSearchParams object for the query string parameters
    const giftIds = [];
    urlSearchParams.forEach((value, key) => {
      if (key.startsWith("gift_id[")) {
        const index = parseInt(key.match(/\[(\d+)\]/)[1]); // get the index from the parameter name
        giftIds[index] = value;
      }
    });
    setObj({
      ...obj,
      gift_id: giftIds,
      user_id: urlSearchParams.get("user_id"),
      token: urlSearchParams.get("token"),
    });
    //eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    let isURLInvalid = Object.values(obj).some(
      (value) => value === null || value === ""
    );
    isURLInvalid ? setValid(false) : setValid(true);
  }, [obj]);

  React.useEffect(() => {
    console.log({ deseos });
    if (obj.user_id && obj.token && deseos && deseos.length > 0)
      getUserPublicInformation(obj.user_id, obj.token).then((res) => {
        if (Number(res) === 403 || Number(res) === 500) {
          setValid(false);
        } else {
          let bd = res.user_date_birthday;
          console.log({ bd, res });
          setUserData({
            ...res,
            user_date_birthday: bd
              ? `${bd.split("/")[0]}/${bd.split("/")[1]}`
              : "",
          });
        }
      });
  }, [obj.user_id, obj.token, deseos]);

  React.useEffect(() => {
    if (
      obj.gift_id &&
      obj.gift_id.length > 0 &&
      obj.token !== null &&
      deseos.length === 0
    ) {
      getGiftData(obj.gift_id, obj.token).then((res) => setDeseos(res));
    }
    //eslint-disable-next-line
  }, [obj]);

  const getGiftData = async (ids, token) => {
    let user_gifts = await listGifts(token);
    if (Number(user_gifts) === 403 || Number(user_gifts) === 500) {
      return setValid(false);
    }
    let array = [];
    array = user_gifts.filter((e) => ids.includes(`${e.ID}`));
    return array;
  };

  /* Inicio Codigo para cambiar formato fecha cumpleaños */
  const MONTHS = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const USDateToES = (str) => {
    let arr = str.split("/");
    let m = arr[0];
    let y = arr[2];
    let d = arr[1];

    return `${d}/${m}/${y}`;
  };

  const userBirthday = (date) => {
    if (date == null) return "";
    let ESDate = USDateToES(date);
    let arr = ESDate.split("/");
    let m = arr[1];
    let checkMonthArr = arr[1].split("0");
    if (checkMonthArr.length > 1) {
      m = checkMonthArr[1];
    }
    let day = arr[0];
    let month = MONTHS[m - 1];

    return `${day} de ${month}`;
  };
  /* Fin Codigo para cambiar formato fecha cumpleaños */

  return (
    <>
      {isValid && deseos ? (
        <>
          <NavbarPublic />
          <div className="p-3 d-flex flex-column align-items-center justify-content-center share-box">
            <div className="share-title">
              <h2 className="text-start">Deseos compartidos</h2>
            </div>
            {/* Tarjeta Perfil */}
            <div className="share-datos-perfil-box">
              {userData ? (
                <>
                  <img
                    src={userData.user_profile_image}
                    alt="foto perfil personalizada"
                    className="share-foto-perfil"
                  />
                  <div className="share-datos-perfil">
                    <p className="nombre-tarjeta">{userData.display_name}</p>
                    <p className="p-tarjeta">Código: {userData.user_code_referred ?? ''}</p>
                    <p className="p-tarjeta">
                      Fecha de Cumpleaños:{" "}
                      {userData.user_date_birthday === ""
                        ? "no ingresada"
                        : userBirthday(userData.user_date_birthday)}
                    </p>
                  </div>
                </>
              ) : (
                <SkeletonProfileBanner />
              )}
            </div>
            <div className="row cards-row" style={{ width: "100%" }}>
              {deseos.length > 0 ? (
                deseos.map((wish, idx) => {
                  return <ShareWishCard wish={wish} key={idx} />;
                })
              ) : (
                <SkeletonWishCard />
              )}
            </div>
          </div>
          <ShareWishFooter />
        </>
      ) : (
        <>
          <NavbarPublic />
          <h1 className="text-center mt-4">Listado de deseos expiró</h1>
        </>
      )}
    </>
  );
};

const SkeletonProfileBanner = () => {
  return (
    <Skeleton
      baseColor="#ccc"
      style={{
        opacity: 0.5,
        width: "100%",
        height: "180px",
      }}
    />
  );
};

const SkeletonWishCard = () => {
  let a = ["", "", "", "", "", ""];
  return (
    <div className="row">
      {a.map((e, i) => (
        <div className="col-12 col-md-6 col-lg-4" key={i}>
          <Skeleton
            baseColor="#ccc"
            style={{
              opacity: 0.5,
              height: "180px",
              minHeight: "350px",
              borderRadius: "15px",
              width: "100%",
              padding: "0",
              maxWidth: "357px",
            }}
          />
        </div>
      ))}
    </div>
  );
};
